import Vue from "vue";
import { Dialog } from "vant";
import {
  showToast,
  hideToast,
  showLoading,
  hideLoading
} from "@/plugins/environment/model/toast"; // 轻提示+loading
import { showNotify } from "@/plugins/environment/model/notify";

// msg提示内容, method回调方法
export const dialogAlert = (msg, method) => {
  let dialog = Dialog;
  console.log("执行dialogAlert");
  dialog
    .alert({
      message: msg,
      className: "showDialogClass"
    })
    .then(() => {
      // on close
      method();
      dialog.close();
    });
};
// 弹框
export const dialogConfirm = (msg, confirm, cancel) => {
  let dialog = Dialog;
  console.log("dialogConfirm");
  dialog
    .confirm({
      message: msg,
      className: "showDialogClass"
    })
    .then(() => {
      console.log("你确认了操作");
      confirm();
    })
    .catch(() => {
      console.log("你取消了操作");
      cancel();
    });
};

//自定义弹窗
export const customAlert = (options, confirm, cancel) => {
  let dialog = Dialog;
  options.className = "showDialogClass";
  dialog
    .confirm(options)
    .then(() => {
      console.log("你确认了操作");
      confirm();
    })
    .catch(() => {
      console.log("你取消了操作");
      cancel();
    });
};

//深拷贝
export function deepClone(origin) {
  var isObject = any => typeof any == "object" && any != null;
  var isArray = any => Object.prototype.toString.call(any) === "[object Array]";
  if (!isObject(origin)) return origin;
  var target = isArray(origin) ? [] : {};
  for (var prop in origin) {
    if (origin.hasOwnProperty(prop)) {
      var value = origin[prop];
      if (isObject(value)) {
        target[prop] = deepClone(value);
      } else {
        target[prop] = value;
      }
    }
  }
  return target;
}

Vue.prototype.$commonMethod = {
  dialogAlert,
  dialogConfirm,
  showToast,
  hideToast,
  showLoading,
  hideLoading,
  showNotify,
  customAlert,
  deepClone,
};
